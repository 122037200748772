/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'
import localforage from 'localforage'
import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://ecf07f4ff58a47efa02aa8a5bc884ac8@o4505590521004032.ingest.sentry.io/4505590525067264",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:app.parrotscage.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const CACHE_NAME = "api-pending-requests"


window.addEventListener("online", () => {
    localforage.getItem(CACHE_NAME).then(entries => {
        JSON.parse(entries).map(request => {
            fetch(request.url, {
                method: "POST",
                body: request.body,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                }
            })
        })
    }).then(localforage.removeItem(CACHE_NAME))
})


export const registerServiceWorker = () => true